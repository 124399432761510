.Links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 35px;

  flex-wrap: nowrap;

  position: relative;
  bottom: 40px;
  opacity: 0;

  transition:
    0.8s cubic-bezier(.1,.43,.5,.95) 0.8s opacity,
    0.8s cubic-bezier(.1,.43,.5,.95) 0.8s bottom,
  ;
}

@media only screen and (max-width: 400px) {
  .dot {
    display: none;
  }
}
.Links p {
  font-weight: 400;
  letter-spacing: 0.04em;
  font-size: 26px;
}

.Links span {
  margin: 0 7px;
  font-size: 0.9em;
}

.Links-icons {  
  display: flex;
  align-items: center;
  fill: #c5c4c4;  
  font-size: 12px !important;
}
.Links-icons svg {
  width: 24px;
  height: 24px;

  margin: 0 7px;
}

.Links-icons a {
  display: flex;
  justify-content: center;
  align-items: center;
}