.Circles {
  height: 1200px;
  width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  z-index: -1;
}

.circle-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  border-radius: 50%;
  border-left: 1px solid rgba(80, 80, 80, 0.3);
  border-top: 1px solid rgba(80, 80, 80, 0.3);
  border-right: 1px solid rgba(80, 80, 80, 0.1);
  border-bottom: 1px solid rgba(80, 80, 80, 0.1);
  animation: 60s linear 0s infinite rotate;
}

@keyframes rotate {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}