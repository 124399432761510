@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

.root {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Roboto Condensed';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
  overflow-x: hidden;

  background-color: #171716;
  color: #E8E8E8;

  overflow: hidden;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Prevent text highlighting / selection */
.noselect {
  -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important; /* Safari */
     -khtml-user-select: none !important; /* Konqueror HTML */
       -moz-user-select: none !important; /* Old versions of Firefox */
        -ms-user-select: none !important; /* Internet Explorer/Edge */
            user-select: none !important; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* Make scrollbar invisible */
.hidescrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hidescrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

input, textarea {
  outline: none;
  padding: 10px 14px;
  background: transparent;
  resize: none;

  border: 1px solid transparent;
  border-bottom: 1px solid rgb(189, 189, 189);

  font-size: 22px;
  color: inherit;
  font-weight: 300;
  font-family: inherit;
}
input:focus, textarea:focus {
  border: 1px solid rgba(87, 87, 87, 0.389);
  border-bottom: 1px solid rgb(189, 189, 189);
}
input::placeholder,
textarea::placeholder {
  color:  inherit;
  font-weight: 100;
  opacity: 0.4;
}

hr {
  height: 2px;
  border: none;
  background-color: #B8B8B8;
}

/* Text styles */
h1 {
  font-size: clamp(50px, 6vw, 60px);
  font-weight: 400;
  line-height: 1.2em;
}
h2 {
  font-size: clamp(50px, 6.45vw, 72px);
  font-weight: 400;
}
h3 {
  font-weight: 100;
  letter-spacing: 0.1em;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
p {
  font-size: 20px;
  letter-spacing: 0.06em;
  font-weight: 300;
}

a {
  font-size: 22px;
  font-weight: 400;
  color: inherit;
  text-decoration: none;
}
a:visited {
  color: inherit;
}