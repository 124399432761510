.Contact {
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 70%;
}

.Contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Contact-name,
.Contact-email,
.Contact-message {
  margin: 7px 0;
}

.Contact-message {
  height: 150px;
}

.Contact-success,
.Contact-error {
  width: 100%; 
  margin: 0 auto;
  font-size: 14px;
  max-height: 50px;
  overflow:hidden;
  transition: max-height 0.1s;
  text-align: right;
}
.Contact-success {
  color: rgb(49, 129, 49);
}
.Contact-error {
  color: rgb(185, 62, 62);
}

.form-action {
  display: flex;
  justify-content: flex-end;
}

.submit {
  border-bottom: 2px solid #288BAA;
  cursor: pointer;
  padding: 3px 1px;
  margin-bottom: 12px;
  margin-top: 5px;

  transition: padding-bottom 0.2s, padding-top 0.2s;;
}
.submit:active {
  border-bottom: 2px solid #288BAA;
}
.submit.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.form-action:hover .submit {
  padding-bottom: 5px;
  padding-top: 1px;
}

.Contact-hr {
  width: 0%;
  margin-top: 15px;

  transition: 
    width 0.7s cubic-bezier(.15,.29,.32,.86) 0.8s,
  ;
}
.Contact-hr.trigger {
  width: 67%;
}


.Contact-link {
  cursor: pointer;
  fill: rgba(255, 255, 255, 0.7);
  transition: fill 0.2s;
}

.Contact-link:hover {
  fill: white;
}

