.intro-text {
  position: relative;
  opacity: 0;
  bottom: 30px;
  transition:
    0.8s cubic-bezier(.1,.43,.5,.95) 0s opacity,
    0.8s cubic-bezier(.1,.43,.5,.95) 0s bottom,
  ;
}
.intro-desc {
  position: relative;
  left: -30px;
  opacity: 0;
  
  transition:
    0.8s cubic-bezier(.1,.43,.5,.95) 0.8s opacity,
    0.8s cubic-bezier(.1,.43,.5,.95) 0.8s left,
  ;
}
.intro-contact {
  position: relative;
  left: -30px;
  opacity: 0;

  transition:
    0.8s cubic-bezier(.1,.43,.5,.95) 0.8s opacity,
    0.8s cubic-bezier(.1,.43,.5,.95) 0.8s left,
  ;
}

.App-photo-box {
  max-height: 0px;
  transition:
    1s cubic-bezier(.1,.43,.5,.95) 0s max-height,
  ;
}
.App-photo-box.trigger {
  max-height: 560px;
}
.headshot {
  opacity: 0;
  transition:
    1s cubic-bezier(.36,.11,.79,.46) 0.5s opacity,
  ;
}
.headshot.trigger {
  opacity: 1;
}


/* Global Transitions */
.in-top {
  opacity: 1 !important;
  bottom: 0 !important; 
}
.in-left {
  opacity: 1 !important;
  left: 0 !important;
}