
/* Projects */
#projects {
  margin-top: 60px;
  margin-bottom: 180px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.project {
  overflow: hidden;
  border-radius: 10px;

  position: relative;

  width: calc(50% - 20px);
  margin: 10px;
}
.project img {
  width: 100%;
  height: 100%;
  filter: blur(0);

  max-width: 500px;

  transition: filter 0.2s;
}
.project:hover img {
  filter: blur(13px);
}

@media only screen and (max-width: 750px) {
  .project {
    width: fit-content;
  }  
}

/* Hovered details */
.project-details {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  background: rgba(0, 0, 0, 0.6);
  text-align: center;

  opacity: 0;
  transition: opacity 0.3s;
}
.project:hover .project-details {
  opacity: 1;
}
.project-details h2 {
  font-weight: 300;
  font-size: clamp(20px, 3.6vw, 40px);
}
.project-details p {
  font-size: clamp(10px, 1.8vw, 20px);
}
.project-links {
  width: 80%;
}
.project-link {
  position: relative;
  width: 100%;
  border: 1px solid grey;
  border-radius: 10px;
  
  cursor: pointer;

  height: clamp(35px, 4.5vw, 50px);
  margin: 15px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: transparent;

  transition: background-color 0.2s, border-color 0.2s;
}
.project-link:hover {
  background: rgba(255, 255, 255, 0.065);
  border-color: rgba(255, 255, 255, 0.656);
}
.project-icon {
  position: absolute;
  left: 15px;
  top: 0;

  width: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  fill: white;
  height: 100%;
}
.live {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #1AA338;
}

.project-description {
  max-width: 70%;
  margin: 0 auto;
  font-size: 18px;
}
.project-tech {
  width: 60%;
  margin: 20px auto 0 auto;
}
p.project-tech {
  font-size: clamp(10px, 1.4vw, 14px);
}

/* I can't wait for content queries */
@media only screen and (max-width: 750px) {
  .project-details h2 {
    font-size: clamp(20px, 6vw, 40px);
  }
  .project-details p {
    font-size: clamp(10px, 3.4vw, 20px);
  }
  
  .project-link {
    height: clamp(35px, 7.4vw, 50px);
  }
  
  p.project-tech {
    font-size: clamp(10px, 2.6vw, 14px);
  }
}

.project {
  position: relative;
  opacity: 0;

  transition:
    0.8s cubic-bezier(.1,.43,.5,.95) 0s opacity,
    0.8s cubic-bezier(.1,.43,.5,.95) 0s left,
  ;
}

.project.trigger {
  opacity: 1;
  left: 0 !important;
}