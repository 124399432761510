.App {
  position: absolute;
  width: 100%;
  height: 100%;

  max-width: 100%;
  max-height: 100%;

  overflow-y: auto;
  overflow-x: hidden;

  scroll-behavior: smooth;


}

/* Parent content wrapper */
.App-content {
  margin: 0 auto;
  padding: 0px 0;

  width: 90%;
  max-width: 1000px;

  left: 0;
  top: 0;

  position: relative;
}

/* Photo */
.App-photo-box {
  position: absolute;
  top: 0;
  right: clamp(60px, 8vw, 80px);
  
  background-color: rgba(120, 116, 116, 0.15);
  width: clamp(200px, 28vw, 293px);
  height: clamp(510px, 60vw, 560px);
}
.headshot {
  position: absolute;
  bottom: 0;
  width: clamp(310px, 39vw, 390px);
  height: auto;

  filter: drop-shadow(9px 0px 30px rgba(40, 139, 170, 0.2));
}


/* Intro section */
#intro {
  margin-top: 120px;
  margin-bottom: 160px;
}
.intro-name {
  font-weight: 400;
  width: fit-content;
  display: inline;
  position: relative;
}
.intro-line {
  position: absolute;
  width: 100%;
  height: 3px;

  left: 0;
  bottom: 5px;
  
  z-index: -1;

  background: #288BAA;
}
#intro p {
  max-width: 470px;
  width: 50%;
  border-left: 2px solid #b7b7b780;
  padding-left: 25px;
  margin-left: 25px;
  margin-top: 18px;

  font-size: clamp(15px, 2vw, 20px)
}
.intro-contact {
  margin-top: 100px;
  border-bottom: 2px solid #288BAA;
  width: fit-content;
  padding: 3px 1px;
}
.intro-contact a {
  position: relative;
  bottom: 0;
  
  transition: bottom 0.2s;
}
.intro-contact:hover a {
  bottom: 2px;
}

@media only screen and (max-width: 540px) {
  h1 {
    font-size: clamp(40px, 9.3vw, 50px);
  }
  h2 {
    text-align: center;
  }
  
  .Links {
    justify-content: center !important;
  }
  .Links > p {
    padding-left: 6px;
  }

  .App-photo-box {
    width: 300px;
    height: 420px;
    right: calc(50% - 150px);
  }
  .headshot {
    width: 315px;
  }

  #intro {
    margin-top: 340px;
    margin-bottom: 90px;
    text-align: center;
  }
  #intro p {
    border-left: none;
    margin: 10px auto;
    width: 80%;
  }

  .intro-contact {
    margin: 0 auto;
    margin-top: 40px;
  }

}


/* Technologies */
#technologies {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 45px 15px;
  margin-bottom: clamp(10px, 8.1vw, 90px);
}
#technologies h3 {
  font-size: clamp(16px, 3.3vw, 36px);
  margin: 25px 10px;
  width: clamp(70px, 19vw, 210px);
}

@media only screen and (max-width: 550px) {
  #technologies {
    text-align: center;
  }
  #technologies h3 {
    margin: 25px 0;
    width: 25%;
    min-width: 95px;
  }
}

/* Contact Section */
#contact {
  display: flex;
  padding-bottom: 50px;
}
.contact-form {
  min-width: 60%;
}

@media only screen and (max-width: 750px) {
  .Contact {
    width: 90% !important;
  }
  #contact {
    flex-direction: column;
  }
}
@media only screen and (max-width: 540px) {
  #contact p {
    text-align: center;
  }
  .form-action {
    justify-content: center !important;
  }
}


/* BG */
.bg-rect,
.bg-rect div {
  background-color: #211C1C;
  background-color: #1c1f217d;
  background-color: #211c1c7d;
  width: 100%;
  position: absolute;
  height: 600px;
}
.bg-rect {
  z-index: -5;
  height: clamp(600px, 100vw, 1100px);
  top: 1200px;
}
.bg-top {
  bottom: clamp(600px, 100vw, 1100px);
  clip-path: polygon(0 30%, 0% 100%, 100% 100%);
}
.bg-bottom {
  top: clamp(600px, 100vw, 1100px);
  clip-path: polygon(0 0, 0% 70%, 100% 0);
}

@media only screen and (max-width: 750px) {
  .bg-rect {
    height: clamp(1000px, 400vw, 2400px)
  }
  .bg-top {
    bottom: clamp(1000px, 400vw, 2400px);
  }
  .bg-bottom {
    top: clamp(1000px, 400vw, 2400px);
  }
}

/* clamp(1000px, 400vw, 3000px); */